<template>
  <CCard>
    <CCardHeader>
      {{ $t("admin.coupons.title") }}

      <CButton
        :to="{ name: 'admin.coupons.create' }"
        class="float-right"
        color="primary"
        size="sm"
        square
        variant="outline"
      >
        {{ $t("buttons.create") }}
      </CButton>
    </CCardHeader>
    <CCardBody>
      <DataTable
        :fields="fields"
        :items="items"
        :loading="isLoading"
        :noItemsView="{
          noResults: $t('admin.no_results'),
          noItems: $t('admin.no_items')
        }"
        border
        hover
        striped
      >
        <template #type="{item}">
          <td class="py-2">
            {{ $t("admin.coupons.types." + item.type) }}
          </td>
        </template>

        <template #show_actions="{item}">
          <td class="py-2 text-center">
            <CButton
              color="primary"
              square
              size="sm"
              class="mr-1"
              @click="EditCoupon(item.id)"
            >
              <CIcon name="cilPencil" />
            </CButton>
            <CButton
              class="mr-1"
              color="danger"
              size="sm"
              square
              @click="deleteCoupon(item.id)"
            >
              <CIcon name="cilTrash"></CIcon>
            </CButton>
          </td>
        </template>
      </DataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import DataTable from "@/components/DataTable";

export default {
  components: {
    DataTable
  },

  data() {
    return {
      isLoading: true,
      items: [],
      fields: [
        { key: "id", label: "ID", _style: "width: 100px" },
        { key: "code", label: this.$t("admin.coupons.code") },
        { key: "type", label: this.$t("admin.coupons.type") },
        { key: "amount", label: this.$t("admin.coupons.amount") },
        { key: "activations", label: this.$t("admin.coupons.activations") },
        { key: "activated", label: this.$t("admin.coupons.activated") },
        { key: "active_from", label: this.$t("admin.date_from") },
        { key: "active_to", label: this.$t("admin.date_to") },
        {
          key: "total_amount_saved",
          label: this.$t("admin.coupons.total_amount_saved")
        },
        {
          key: "show_actions",
          label: this.$t("admin.actions"),
          _style: "width: 150px"
        }
      ]
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      this.isLoading = true;
      this.$http.get("/api/admin/coupons").then(response => {
        this.items = response.data.data;
        this.isLoading = false;
      });
    },
    EditCoupon(id) {
      this.$router.push({
        name: "admin.coupons.edit",
        query: { id: id, edit: true }
      });
    },
    deleteCoupon(id) {
      this.isLoading = true;
      this.$http.delete(`/api/admin/coupons/${id}`).then(() => this.getData());
    }
  },

  metaInfo() {
    return {
      title: this.$t("admin.coupons.title")
    };
  }
};
</script>
