<template>
  <div>
    <CRow align-horizontal="center">
      <CCol sm="6">
        <CCard>
          <div v-if="isLoading" class="loader">
            <CSpinner color="primary" grow style="width:4rem; height:4rem;" />
          </div>

          <CCardHeader>
            {{ $t("admin.coupons.create.title") }}

            <CButton
              class="float-right"
              color="primary"
              size="sm"
              square
              variant="outline"
              @click="save"
            >
              {{ $t("buttons.save") }}
            </CButton>
          </CCardHeader>
          <CCardBody>
            <CAlert
              v-if="Object.entries(errors).length"
              :fade="false"
              color="danger"
            >
              {{ errors.message }}
              <ul>
                <li v-for="(error, field) in errors.errors" :key="field">
                  {{ error[0] }}
                </li>
              </ul>
            </CAlert>
            <CRow>
              <CCol sm="8">
                <CInput
                  v-model="form.code"
                  :label="$t('admin.coupons.code')"
                  :disabled="form.mode === 'edit'"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="4">
                <label>{{ $t("admin.coupons.type") }}</label>
                <CSelect
                  :options="types"
                  :value.sync="form.type"
                  :disabled="form.mode === 'edit'"
                />
              </CCol>
              <CCol sm="4">
                <CInput
                  v-model="form.amount"
                  :disabled="form.type === 'free_delivery' || form.type === 'welcome' || form.mode === 'edit'"
                  :label="$t('admin.coupons.amount')"
                />
              </CCol>
              <CCol sm="4">
                <CInput
                  v-model="form.activations"
                  :label="$t('admin.coupons.activations')"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="6">
                <label>{{ $t("admin.date_from") }}</label>
                <v-date-picker
                  v-model="form.active_from"
                  :disabled="form.mode === 'edit'"
                  :model-config="modelConfig"
                  timezone="UTC"
                >
                  <template #default="{ inputValue, togglePopover }">
                    <CInput
                      :value="inputValue"
                      readonly
                      :disabled="form.mode === 'edit'"
                      @click="togglePopover"
                    />
                  </template>
                </v-date-picker>
              </CCol>
              <CCol sm="6">
                <label>{{ $t("admin.date_to") }}</label>
                <v-date-picker
                  v-model="form.active_to"
                  :model-config="modelConfig"
                  timezone="UTC"
                >
                  <template #default="{ inputValue, togglePopover }">
                    <CInput
                      :value="inputValue"
                      readonly
                      @click="togglePopover"
                    />
                  </template>
                </v-date-picker>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      errors: [],
      types: [],
      form: {
        type: null,
        code: null,
        amount: 0,
        activations: 0,
        active_from: new Date(new Date().setHours(0, 0, 0, 0)),
        active_to: new Date(new Date().setHours(0, 0, 0, 0)),
        mode: "new"
      },
      modelConfig: {
        timeAdjust: "00:00:00"
      }
    };
  },

  created() {
    this.types = Object.entries(
      this.$t("admin.coupons.types")
    ).map(([key, value]) => ({ value: key, label: value }));
    this.form.type = this.types[0].value;
    if (this.$route.query.edit) this.show(this.$route.query.id);
  },

  methods: {
    save() {
      this.isLoading = true;
      this.errors = [];

      if (this.form.mode === "new") {
        this.$http
          .post(`/api/admin/coupons`, this.form)
          .then(() => this.$router.push({ name: "admin.coupons" }))
          .catch(error => (this.errors = error.response.data))
          .finally(() => (this.isLoading = false));
      } else {
        this.$http
          .put(`/api/admin/coupons/${this.$route.query.id}`, this.form)
          .then(() => this.$router.push({ name: "admin.coupons" }))
          .catch(error => (this.errors = error.response.data))
          .finally(() => (this.isLoading = false));
      }
    },
    show(id) {
      this.$http.get(`/api/admin/coupons/${id}`).then(response => {
        let date = response.data.data.active_from.split('-');
        this.form.active_from = new Date(date[2],date[1]-1,date[0]);
        date = response.data.data.active_to.split('-');
        this.form.active_to = new Date(date[2],date[1]-1,date[0]);
        this.form.amount = response.data.data.amount;
        this.form.activations = response.data.data.activations;
        this.form.type = response.data.data.type;
        this.form.code = response.data.data.code;
        this.form.mode = "edit";

        this.isLoading = false;
      });
    }
  },

  metaInfo() {
    return {
      title: this.$route.query.edit
        ? this.$t("admin.coupons.edit.title")
        : this.$t("admin.coupons.create.title")
    };
  }
};
</script>
